<template>
  <div>
    <section>
      本規約は、株式会社晴れる屋（以下「私達」といいます。）が提供するプロフィールカード制作ツール（以下「本ツール」といいます。）について、本ツールを利用される皆様（以下「利用者」といいます。）の利用条件等を定めます。<br />
      私達は、私達の判断において、いつでも本規約の内容を変更できます。
    </section>
    <section>
      <h3>利用条件</h3>
      利用者は、本ツールの利用時点において、本規約に同意した上で、本ツールを利用しなければなりません。
    </section>
    <section>
      <h3>禁止事項</h3>
      利用者は、本ツールの利用にあたり、次に掲げる行為のいずれかに該当する行為を行い、また他者に行わせてはなりません。
      <ol>
        <li>
          法令違反行為等
          <ul>
            <li>
              法令、裁判所の判決、決定もしくは命令、または法令上拘束力のある行政措置に違反する行為
            </li>
            <li>第三者に対する脅迫行為</li>
            <li>公序良俗に反しまたは善良な風俗を害する行為</li>
            <li>
              第三者の知的財産権、肖像権、プライバシー、名誉その他の権利または利益を侵害する行為
            </li>
          </ul>
        </li>
        <li>
          不適切情報の公開等
          <ul>
            <li>
              以下に定める情報を公開する行為（同音異義語、伏せ字、当て字またはリンク等を利用して当該情報を送信、伝達、投稿または公開する行為を含みます。）
              <ul>
                <li>
                  過度に暴力的な表現、残虐な表現、差別的な表現その他の他人に不快感を与えるおそれのある情報
                </li>
                <li>未成年者に悪影響を及ぼすおそれのある情報</li>
                <li>自己または第三者の個人情報</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          営利行為等
          <ul>
            <li>本ツールを利用した営利行為</li>
          </ul>
        </li>
        <li>
          その他の不正行為等
          <ul>
            <li>本規約に違反する行為または私達が不適切と判断する行為</li>
          </ul>
        </li>
      </ol>
    </section>
    <section>
      <h3>免責</h3>
      <ol>
        <li>
          利用者は自己責任において本ツールを利用するものとし、利用者と他の利用者との紛争その他のトラブル等に関して、私達は一切の責任を負いません。また、私達は第三者の本ツールの利用等について一切の責任を負いません。
        </li>
        <li>
          私達は、私達の事情または天災地変、疫病、社会的動乱、ストライキ、火災、停電、通信インフラの停止その他の私達の支配できない事由に起因して、本ツールの内容を変更すること、または本ツール利用を中断・中止することがあります。
        </li>
        <li>
          私達の故意・重過失による場合、その他法令で免責が認められない場合を除き、私達は、本ツールに関する一切の責任を負いません。
        </li>
      </ol>
    </section>
  </div>
</template>

<script>
export default {
  name: "termsText",
};
</script>

<style lang="scss" scoped>
div {
  padding-top: 1.25rem;
}
section + section {
  margin-top: 1rem;
}
h3 {
  margin-bottom: 0.25rem;
}
</style>